import * as React from "react";
import { useRef, useState } from "react";
import { liveHolder } from "./Pages.module.scss";
import { motion } from "framer-motion";
import Seo from "../components/utilities/Seo";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import useScript from "../hooks/useScript";
import { withPrefix } from "gatsby";

const LivePage = () => {
   const [showHelmet, setShowHelmet] = useState(false);

   const variants = {
      pre: {
         opacity: 0,
      },
      visible: {
         opacity: 1,
         transition: {
            delay: 1,
            type: "tween",
            duration: 1,
         },
      },
   };

   useScript(withPrefix("/js/songkickWidget.js"));
   //  let timerRef = useRef;
   //  const variants = {
   //     pre: {
   //        opacity: 0,
   //     },
   //     visible: {
   //        opacity: 1,
   //        transition: {
   //           delay: 1,
   //           type: "tween",
   //           duration: 1,
   //        },
   //     },
   //  };

   //  useEffect(() => {
   //     timerRef.current = setTimeout(() => setShowHelmet(true), 5000);

   //     return clearTimeout(timerRef.current);
   //  }, []);

   return (
      <article className={liveHolder}>
         {/* {
            <Helmet>
               <script src="//widget.songkick.com/1120884/widget.js"></script>
            </Helmet>
         } */}

         <h4>Live</h4>
         <Seo
            title="Live"
            description="Kyla La Grange live shows"
            imageFile={"KylaLaGrangeLiveCard.jpg"}
         />
         <motion.div
            className="songkickWidget"
            variants={variants}
            initial="pre"
            animate="visible"
         >
            <a
               href="https://www.songkick.com/artists/2328732"
               className="songkick-widget"
               data-theme="light"
               data-track-button="on"
               data-detect-style="true"
               data-background-color="transparent"
               data-locale="en"
            >
               Kyla La Grange tour dates
            </a>
         </motion.div>
         <div>
            <p>
               Dates not showing?{" "}
               <a
                  href="https://www.songkick.com/artists/2328732-kyla-la-grange"
                  style={{ textDecoration: "underline" }}
               >
                  Head to Songkick
               </a>
            </p>
         </div>
      </article>
   );
};

export default LivePage;
